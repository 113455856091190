import React, { useState } from 'react'
import { colors, presets } from '../../utils/theme'
import { rhythm } from '../../utils/typography'
import _ from 'lodash'
import { navigate } from 'gatsby-link'
import { styles as buttonStyles} from '../../components/Button'
import Layout from '../../components/Layout'
import { encode } from '.'

const styles = {
  form: {
    justifyContent: 'space-between',
    margin: `0 auto ${rhythm(5)} auto`,
    padding: rhythm(2),
    border: `1px solid ${colors.blueGreen}`,
    borderRadius: '20px',
    width: '85%',
    [presets.Tablet]: {
      width: '60%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      maxWidth: '600px',
    },
  },
  inputContainer: {
    width: '100%',
    margin: '0.3rem auto',
    [presets.Tablet]: {
      margin: '0.3rem',
      width: '47%',
    }
  },
  input: {
    width: '100%',
    border: `1px solid ${colors.mediumGrey}`,
    padding: `${rhythm(0.4)} ${rhythm(0.1)} ${rhythm(0.4)} ${rhythm(0.9)}`,
    borderRadius: '5px',
    fontSize: rhythm(0.75),
    '::placeholder': {
      color: colors.mediumGrey,
    },
  },
  buttonContainer: {
    width: '100%',
  },
  button: {
    marginTop: rhythm(0.5),
    cursor: 'pointer',
    width: '100%',
    border: 'none',
  },
  contact: {
    display: 'none'
  }
}

export function ContactForm() {
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  })

  const handlePhoneNumberInput = (value) => {
    if (value) {
      const phoneNumber = value.replace(/[^\d]/g, "")
      const phoneNumberLength = phoneNumber.length

      if (phoneNumberLength < 4) {
        return phoneNumber
      }
      if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
      }
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
    }
  }

  const handleOnChange = (e) => {
    let value = e.target.value
    if (e.target.name === 'phoneNumber') {
      value = handlePhoneNumberInput(value)
    }
    setFormState({
      ...formState,
      [e.target.name]: value
    })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...formState
      })
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(() => console.error())
  }

  return (
    <form
      name="get-your-license-contact"
      method='post'
      action="/contact/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      css={styles.form}
      onSubmit={handleSubmit}
    >
      <input className="input" type="hidden" name="form-name" value="get-your-license-contact" required={false} />
      <div hidden>
        <input className="input" name="bot-field" onChange={handleOnChange} />
      </div>
      <div className='field' css={styles.inputContainer}>
        <input className="input" css={styles.input} type="text" name="firstName" id="firstName" placeholder="First Name" onChange={handleOnChange} required={true} />
      </div>
      <div className='field' css={styles.inputContainer}>
        <input className="input" css={styles.input} type="text" name="lastName" id="lastName" placeholder="Last Name" onChange={handleOnChange} required={true} />
      </div>
      <div className='field' css={styles.inputContainer}>
        <input className="input" css={styles.input} type="email" name="email" id="email" placeholder="Email" onChange={handleOnChange} required={true} />
      </div>
      <div className='field' css={styles.inputContainer}>
        <input className="input" css={styles.input} type="tel" name="phoneNumber" id="phoneNumber" placeholder="Phone Number" onChange={handleOnChange} value={_.get(formState, "phoneNumber")} required={true} maxLength={14} minLength={14} />
      </div>
      <div className='field' css={styles.buttonContainer}>
        <button className="button is-link" type='submit' css={[buttonStyles.primary, styles.button]}>Submit</button>
      </div>
    </form>
  )
}

export const ContactFormPage = () => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <ContactForm />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactFormPage
